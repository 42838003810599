/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */

import Hammer from 'hammerjs';

export default (() => {
  const STYLES = {
    carousel() {
      const seconds = this.animationDuration / 1000;
      return `
      .slider-${this.uuid} > .slider-items {
        height: ${this.dimensions.containerHeight}px;
        width: ${this.dimensions.containerWidth}px;

        overflow: hidden;
      }
      .slider-${this.uuid} > .slider-items > * {
        position: relative;
        width: 0;
        height: 0;
        transition: top ${seconds}s ease-in-out, left ${seconds}s ease-in-out, transform ${seconds}s, opacity ${seconds}s;
      }
      .slider-${this.uuid} .slide-content  {
        height: ${this.dimensions.slideHeight}px;
        width: ${this.dimensions.slideWidth}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      `;
    },
    'carousel-ring': function carouselRing() {
      const seconds = this.animationDuration / 1000;
      return `
      .slider-${this.uuid} > .slider-items {
        height: ${this.dimensions.containerHeight}px;
        width: ${this.dimensions.containerWidth}px;

        overflow: hidden;
      }
      .slider-${this.uuid} > .slider-items > * {
        position: relative;
        width: 0;
        height: 0;
        transition: top ${seconds}s ease-in-out, left ${seconds}s ease-in-out, transform ${seconds}s, opacity ${seconds}s;
      }
      .slider-${this.uuid} .slide-content  {
        height: ${this.dimensions.slideHeight}px;
        width: ${this.dimensions.slideWidth}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      `;
    },
    slider() {
      const seconds = this.animationDuration / 1000;
      return `
      .slider-${this.uuid} > .slider-items {
        height: ${this.dimensions.containerHeight}px;
        width: ${this.dimensions.containerWidth}px;

        overflow: hidden;
      }
      .slider-${this.uuid} > .slider-items > * {
        position: relative;
        width: 0;
        height: 0;
        transition: left ${seconds}s;
      }
      .slider-${this.uuid} .slide-content  {
        height: ${this.dimensions.slideHeight}px;
        width: ${this.dimensions.slideWidth}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      `;
    },
  };

  const INLINE_STYLES = {
    carousel(i) {
      const {
        containerHeight, containerWidth, slideWidth,
      } = this.dimensions;
      const styles = [
        `
          top: 0;
          left: ${containerWidth / 2 - slideWidth / 2}px;
          z-index: 4;
          transform: scale(1);
          opacity: 1;
        `,
        `
          top: ${containerHeight / 10}px;
          left: ${containerWidth - slideWidth * 0.5}px;
          z-index: 3;
          transform: scale(0.5);
          opacity: 1;
        `,
        `
          top: ${containerHeight / 10}px;
          left: ${containerWidth + slideWidth * 0.4}px;
          z-index: 1;
          transform: scale(0.5);
          opacity: 0;
        `,
        `
          top: ${containerHeight / 10}px;
          left: ${-slideWidth * 1.4}px;
          z-index: 1;
          transform: scale(0.5);
          opacity: 0;
        `,
        `
          top: ${containerHeight / 10}px;
          left: 0;
          z-index: 2;
          transform: scale(0.5);
          opacity: 1;
        `,
      ];
      if (this.slides.length === 5) return styles[i];
      if (this.slides.length === 6) {
        if (i < 3) return styles[i];
        if (i === 3) return styles[7];
        return styles[i - 1];
      }
      const slidesBetween = this.slides.length - 5;
      if (i < 3) return styles[i];
      if (i < this.slides.length - 2) {
        if (i === 3) return styles[6];
        if (i === this.slides.length - 3) return styles[5];
        return styles[7];
      }
      return styles[i - slidesBetween];
    },
    'carousel-ring': function carouselRing(i) {
      const {
        containerHeight, containerWidth, slideHeight, slideWidth,
      } = this.dimensions;
      const styles = [
        `
          top: ${containerHeight - slideHeight}px;
          left: ${containerWidth / 2 - slideWidth / 2}px;
          z-index: 3;
          transform: scale(1);
          opacity: 1;
        `,
        `
          top: ${containerHeight * 0.4 - slideHeight / 2}px;
          left: ${containerWidth - slideWidth * 0.8}px;
          z-index: 2;
          transform: scale(0.8);
          opacity: 0.25;
          cursor: pointer;
        `,
        `
          top: 0;
          left: ${containerWidth / 9 * 5}px;
          z-index: 1;
          transform: scale(0.5);
          opacity: 0.1;
        `,
        `
          top: 0;
          left: ${containerWidth / 9 * 4 - slideWidth / 2}px;
          z-index: 1;
          transform: scale(0.5);
          opacity: 0.1;
        `,
        `
          top: ${containerHeight * 0.4 - slideHeight / 2}px;
          left: 0;
          z-index: 2;
          transform: scale(0.8);
          opacity: 0.25;
          cursor: pointer;
        `,
        `
          top: 0;
          left: ${containerWidth / 4}px;
          z-index: 1;
          transform: scale(0);
          opacity: 0;
        `,
        `
          top: 0;
          left: ${containerWidth / 4 * 3}px;
          z-index: 1;
          transform: scale(0);
          opacity: 0;
        `,
        `
          top: 0;
          left: ${containerWidth / 2}px;
          z-index: 1;
          transform: scale(0);
          opacity: 0;
        `,
      ];
      if (this.slides.length === 5) return styles[i];
      if (this.slides.length === 6) {
        if (i < 3) return styles[i];
        if (i === 3) return styles[7];
        return styles[i - 1];
      }
      const slidesBetween = this.slides.length - 5;
      if (i < 3) return styles[i];
      if (i < this.slides.length - 2) {
        if (i === 3) return styles[6];
        if (i === this.slides.length - 3) return styles[5];
        return styles[7];
      }
      return styles[i - slidesBetween];
    },
    slider(i) {
      const {
        slideWidth,
      } = this.dimensions;
      const styles = [
        `
          top: 0;
          left: 0;
          z-index: 4;
          opacity: 1;
        `,
        `
          top: 0;
          left: ${slideWidth * i}px;
          z-index: 3;
          opacity: 1;
        `,
        `
          top: 0;
          left: ${slideWidth * i}px;
          z-index: 1;
          opacity: 1;
        `,
        `
          display: none;
          top: 0;
          left: ${slideWidth * i}px;
          z-index: 1;
          opacity: 1;
        `,
        `
          left: ${-slideWidth}px;
          z-index: 2;
          opacity: 1;
        `,
      ];
      if (i === 0) return styles[0];
      if (i === 1) return styles[1];
      if (i === this.slides.length - 2) return styles[3];
      if (i === this.slides.length - 1) return styles[4];
      return styles[2];
    },
  };

  const HANDLERS = {
    carousel() {
      this.slides.forEach((_, index) => {
        this.slides[index].style = INLINE_STYLES[this.type].call(this, index);
      });
      if (this.activeStyle) this.slides[0].style.cssText += `\n${this.activeStyle}`;
    },
    'carousel-ring': function carouselRing() {
      this.slides.forEach((_, index) => {
        this.slides[index].style = INLINE_STYLES[this.type].call(this, index);
      });
      if (this.activeStyle) this.slides[0].style.cssText += `\n${this.activeStyle}`;
    },
    slider() {
      this.slides.forEach((_, index) => {
        this.slides[index].style = INLINE_STYLES[this.type].call(this, index);
      });
      if (this.activeStyle) this.slides[0].style.cssText += `\n${this.activeStyle}`;
    },
  };

  return class SlidezNStuff {
    constructor(config) {
      this.animationDuration = config.animationDuration || 1000;
      this.activeClass = config.activeClass;
      this.activeStyle = config.activeStyle;
      this.breakpoints = config.breakpoints;
      this.autoChange = config.autoChange;

      this.sliderElement = window.document.querySelector(config.sliderElement);
      if (config.previousButton) {
        window.document.querySelector(config.previousButton).addEventListener('click', this.previous.bind(this));
      }
      if (config.nextButton) {
        window.document.querySelector(config.nextButton).addEventListener('click', this.next.bind(this));
      }

      this.uuid = SlidezNStuff.generateUUID();
      this.sliderElement.classList.add(`slider-${this.uuid}`);
      this.itemsContainer = this.sliderElement.querySelector('.slider-items');
      this.slides = [...this.itemsContainer.children];

      // let's assume everything is just fine
      //
      // if (this.slides.length < 5) {
      //   throw new Error('Carousel must contain at least 5 elements');
      // }

      if (this.activeClass) this.slides[0].classList.add(this.activeClass);

      for (const breakpoint of this.breakpoints) {
        window.matchMedia(`(min-width: ${breakpoint.minWidth}px)`).addListener(() => this.handleBreakpoints());
      }

      this.handleBreakpoints();

      this.slides.forEach((slide) => {
        slide.addEventListener('click', () => {
          if (this.type === 'carousel-ring') {
            if (slide === this.slides[1]) {
              this.next();
            } else if (slide === this.slides[this.slides.length - 1]) {
              this.previous();
            }
          }
        });
      });

      if (this.autoChange) {
        this.setupInterval();
        this.sliderElement.addEventListener('mouseover', () => clearInterval(this.autoChangeHandler));
        this.sliderElement.addEventListener('mouseout', () => this.setupInterval());
      }

      this.hammerjs = new Hammer(this.sliderElement);

      this.hammerjs.on('swipeleft', () => {
        if (this.type === 'slider') {
          this.next();
        }
      });

      this.hammerjs.on('swiperight', () => {
        if (this.type === 'slider') {
          this.previous();
        }
      });

      return this;
    }

    setupInterval() {
      this.autoChangeHandler = setInterval(() => {
        this.next();
      }, this.autoChange);
    }

    handleBreakpoints() {
      const { innerWidth } = window;
      for (const breakpoint of this.breakpoints) {
        if (innerWidth >= breakpoint.minWidth) {
          this.type = breakpoint.config.type;
          this.dimensions = breakpoint.config.dimensions;
          this.updateStyles();
          return;
        }
      }
    }

    updateStyles() {
      const style = STYLES[this.type].call(this);
      let el = window.document.querySelector(`style#slidez-${this.uuid}`);
      if (!el) {
        el = window.document.createElement('style');
        el.id = `slidez-${this.uuid}`;
        window.document.head.appendChild(el);
      }
      el.innerHTML = style;

      HANDLERS[this.type].call(this);
    }

    next() {
      if (this.animating) return false;
      if (this.activeClass) this.slides[0].classList.remove(this.activeClass);
      this.slides.push(this.slides.shift());
      if (this.activeClass) this.slides[0].classList.add(this.activeClass);
      this.animating = true;
      HANDLERS[this.type].call(this);
      setTimeout(() => {
        this.animating = false;
      }, this.animationDuration);
      return true;
    }

    previous() {
      if (this.animating) return false;
      if (this.activeClass) this.slides[0].classList.remove(this.activeClass);
      this.slides.unshift(this.slides.pop());
      if (this.activeClass) this.slides[0].classList.add(this.activeClass);
      this.animating = true;
      HANDLERS[this.type].call(this);
      setTimeout(() => {
        this.animating = false;
      }, this.animationDuration);
      return true;
    }

    static generateUUID(a) {
      if (a) {
        return (a ^ Math.random() * 16 >> a / 4).toString(16);
      }
      return (String(1e7)).replace(/[018]/g, this.generateUUID);
    }
  };
})();
