const body = document.querySelector('body');
const menu = document.querySelector('.menu-icon');

const toggleClass = (element, stringClass) => {
  if (element.classList.contains(stringClass)) {
    element.classList.remove(stringClass);
  } else {
    element.classList.add(stringClass);
  }
};

const applyListeners = () => {
  menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
};

const links = [...document.querySelectorAll('.mobile-menu__list-item')].slice(0, -1);

links.forEach((el) => {
  el.addEventListener('click', () => {
    window.scrollTo({
      top: window.scrollY + document.querySelector(`#${el.dataset.target}`).getBoundingClientRect().top - document.querySelector('nav.nav').offsetHeight,
      behavior: 'smooth',
    });
    body.classList.toggle('nav-active');
  });
});

applyListeners();
