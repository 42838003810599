
import Slider from './slider';

export default () => {
  const gallery = new Slider({
    sliderElement: '.gallery .slider',
    previousButton: '.gallery .slider-arrow-previous',
    nextButton: '.gallery .slider-arrow-next',
    autoChange: 5000,
    breakpoints: [
      {
        minWidth: 1500,
        config: {
          type: 'carousel',
          dimensions: {
            containerHeight: 365,
            containerWidth: 1320,
            slideHeight: 365,
            slideWidth: 605,
          },
        },
      },
      {
        minWidth: 1200,
        config: {
          type: 'carousel',
          dimensions: {
            containerHeight: 275,
            containerWidth: 1000,
            slideHeight: 271,
            slideWidth: 450,
          },
        },
      },
      {
        minWidth: 900,
        config: {
          type: 'carousel',
          dimensions: {
            containerHeight: 250,
            containerWidth: 720,
            slideHeight: 211,
            slideWidth: 350,
          },
        },
      },
      {
        minWidth: 700,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 332,
            containerWidth: 550,
            slideHeight: 332,
            slideWidth: 550,
          },
        },
      },
      {
        minWidth: 500,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 211,
            containerWidth: 350,
            slideHeight: 211,
            slideWidth: 350,
          },
        },
      },
      {
        minWidth: 375,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 168,
            containerWidth: 276,
            slideHeight: 168,
            slideWidth: 276,
          },
        },
      },
      {
        minWidth: 0,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 175,
            containerWidth: 290,
            slideHeight: 175,
            slideWidth: 290,
          },
        },
      },
    ],
  });

  const clients = new Slider({
    sliderElement: '.clients .slider',
    previousButton: '.clients .slider-arrow-previous',
    nextButton: '.clients .slider-arrow-next',
    autoChange: 4000,
    breakpoints: [
      {
        minWidth: 1500,
        config: {
          type: 'carousel-ring',
          dimensions: {
            containerHeight: 463,
            containerWidth: 1194,
            slideHeight: 481,
            slideWidth: 673,
          },
        },
      },
      {
        minWidth: 1200,
        config: {
          type: 'carousel-ring',
          dimensions: {
            containerHeight: 463,
            containerWidth: 1022,
            slideHeight: 481,
            slideWidth: 673,
          },
        },
      },
      {
        minWidth: 1050,
        config: {
          type: 'carousel-ring',
          dimensions: {
            containerHeight: 400,
            containerWidth: 886,
            slideHeight: 400,
            slideWidth: 600,
          },
        },
      },
      {
        minWidth: 900,
        config: {
          type: 'carousel-ring',
          dimensions: {
            containerHeight: 400,
            containerWidth: 736,
            slideHeight: 400,
            slideWidth: 600,
          },
        },
      },
      {
        minWidth: 700,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 234,
            containerWidth: 550,
            slideHeight: 234,
            slideWidth: 550,
          },
        },
      },
      {
        minWidth: 500,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 200,
            containerWidth: 342,
            slideHeight: 146,
            slideWidth: 342,
          },
        },
      },
      {
        minWidth: 400,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 200,
            containerWidth: 322,
            slideHeight: 137,
            slideWidth: 322,
          },
        },
      },
      {
        minWidth: 0,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 200,
            containerWidth: 300,
            slideHeight: 127,
            slideWidth: 300,
          },
        },
      },
    ],
  });

  const opinions = new Slider({
    sliderElement: '.opinions .slider',
    nextButton: '.opinions .slider-arrow-next',
    autoChange: 10000,
    breakpoints: [
      {
        minWidth: 1200,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 396,
            containerWidth: 768,
            slideHeight: 396,
            slideWidth: 768,
          },
        },
      },
      {
        minWidth: 900,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 396,
            containerWidth: 720,
            slideHeight: 396,
            slideWidth: 720,
          },
        },
      },
      {
        minWidth: 800,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 276,
            containerWidth: 636,
            slideHeight: 276,
            slideWidth: 636,
          },
        },
      },
      {
        minWidth: 700,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 276,
            containerWidth: 536,
            slideHeight: 276,
            slideWidth: 536,
          },
        },
      },
      {
        minWidth: 600,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 276,
            containerWidth: 436,
            slideHeight: 276,
            slideWidth: 436,
          },
        },
      },
      {
        minWidth: 450,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 276,
            containerWidth: 336,
            slideHeight: 276,
            slideWidth: 336,
          },
        },
      },
      {
        minWidth: 0,
        config: {
          type: 'slider',
          dimensions: {
            containerHeight: 276,
            containerWidth: 300,
            slideHeight: 276,
            slideWidth: 300,
          },
        },
      },
    ],
  });
};
