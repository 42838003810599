import jQuery from 'jquery';

export default () => {
  let prevScrollpos = window.pageYOffset;
  const navbar = document.querySelector('.nav');
  const menuIcon = document.querySelector('.menu-icon');
  
  const scrollItems = [...document.querySelectorAll('.nav .link a')].map(link => document.querySelector(link.attributes.href.value)).reverse();
  
  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    const navbarOffsetTop = jQuery('.nav').offset().top;
    const offset = window.innerHeight * 0.05;
  
    if (currentScrollPos === 0) {
      navbar.classList.add('nav-ontop');
      document.body.classList.add('on-top');
      document.body.classList.remove('nav-hidden');
      navbar.style.marginTop = null;
      menuIcon.style.marginTop = null;
    } else if (currentScrollPos < navbar.offsetHeight) {
      document.body.classList.remove('nav-hidden');
      navbar.style.marginTop = null;
      menuIcon.style.marginTop = null;
    } else if (navbarOffsetTop < offset) {
      navbar.classList.remove('nav-ontop');
      document.body.classList.remove('on-top');
      document.body.classList.remove('nav-hidden');
    } else if (prevScrollpos > currentScrollPos) {
      navbar.classList.remove('nav-ontop');
      document.body.classList.remove('on-top');
      document.body.classList.remove('nav-hidden');
      navbar.style.marginTop = null;
      menuIcon.style.marginTop = null;
    } else {
      navbar.style.padding = null;
      document.body.classList.add('nav-hidden');
    }
  
    prevScrollpos = currentScrollPos;
  
    // eslint-disable-next-line no-restricted-syntax
    for (const item of scrollItems) {
      if (item.getBoundingClientRect().top <= 340) {
        const activeLink = document.querySelector('.link__current');
        if (activeLink.children[0].attributes.href.value !== `${item.id}`) {
          activeLink.classList.remove('link__current');
          document.querySelector(`.link-${item.id}`).classList.add('link__current');
        }
        return;
      }
    }
  };
};
