export default () => {
  if (!window.localStorage.getItem('dark')) {
    window.localStorage.setItem('dark', window.matchMedia('(prefers-color-scheme: dark)').matches);
  }

  window.switchTheme = function switchTheme() {
    if (document.body.classList.contains('dark')) {
      setLight();
    } else {
      setDark();
    }
  };

  const setDark = () => {
    document.body.classList.add('dark');
    window.pJSDom[0].pJS.particles.line_linked.color = '#ffffff';
    window.pJSDom[0].pJS.particles.line_linked.opacity = 0.2;
    window.pJSDom[0].pJS.fn.particlesRefresh();
    window.localStorage.setItem('dark', true);
  };

  const setLight = () => {
    document.body.classList.remove('dark');
    window.pJSDom[0].pJS.particles.line_linked.color = '#000000';
    window.pJSDom[0].pJS.particles.line_linked.opacity = 0.5;
    window.pJSDom[0].pJS.fn.particlesRefresh();
    window.localStorage.setItem('dark', false);
  };

  if (window.localStorage.getItem('dark') === 'true') {
    setDark();
  }
};
