// based on https://codepen.io/the_ruther4d/pen/mxMbqP/ with added code for lazyloading iframes

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    let lazyloadImages;
    let lazyloadIframes;
    let lazyloadPictures;

    if ('IntersectionObserver' in window) {
      lazyloadImages = document.querySelectorAll('.lazy');
      const imageObserver = new IntersectionObserver(((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = entry.target;
            image.classList.remove('lazy');
            imageObserver.unobserve(image);
          }
        });
      }));

      lazyloadImages.forEach((image) => {
        imageObserver.observe(image);
      });

      lazyloadIframes = document.querySelectorAll('iframe.iframe-lazy');
      const iframeObserver = new IntersectionObserver(((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const iframe = entry.target;
            iframe.src = iframe.dataset.src;
            iframe.classList.remove('iframe-lazy');
            iframeObserver.unobserve(iframe);
          }
        });
      }));

      lazyloadIframes.forEach((iframe) => {
        iframeObserver.observe(iframe);
      });

      lazyloadPictures = document.querySelectorAll('picture.picture-lazy');
      const pictureObserver = new IntersectionObserver(((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const picture = entry.target;
            [...picture.children].forEach((el) => {
              if (el.dataset.src) {
                el.src = el.dataset.src;
              } else {
                el.srcset = el.dataset.srcset;
              }
            });
            picture.classList.remove('picture-lazy');
            pictureObserver.unobserve(picture);
          }
        });
      }));

      lazyloadPictures.forEach((picture) => {
        pictureObserver.observe(picture);
      });
    } else {
      let lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll('.lazy');
      lazyloadIframes = document.querySelectorAll('iframe.iframe-lazy');
      lazyloadPictures = document.querySelectorAll('picture.picture-lazy');

      const lazyload = () => {
        if (lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(() => {
          const scrollTop = window.pageYOffset;
          lazyloadImages.forEach((img) => {
            if (img.offsetTop < window.innerHeight + scrollTop) {
              img.src = img.dataset.src;
              img.classList.remove('lazy');
            }
          });
          lazyloadIframes.forEach((iframe) => {
            if (iframe.offsetTop < window.innerHeight + scrollTop) {
              iframe.src = iframe.dataset.src;
              iframe.classList.remove('iframe-lazy');
            }
          });
          lazyloadPictures.forEach((picture) => {
            if (picture.offsetTop < window.innerHeight + scrollTop) {
              [...picture.children].forEach((el) => {
                if (el.dataset.src) {
                  el.src = el.dataset.src;
                } else {
                  el.srcset = el.dataset.srcset;
                }
              });
              picture.classList.remove('iframe-lazy');
            }
          });
          if (lazyloadImages.length === 0 && lazyloadIframes.length === 0 && lazyloadPictures.length === 0) {
            document.removeEventListener('scroll', lazyload);
            window.removeEventListener('resize', lazyload);
            window.removeEventListener('orientationChange', lazyload);
          }
        }, 20);
      };

      document.addEventListener('scroll', lazyload);
      window.addEventListener('resize', lazyload);
      window.addEventListener('orientationChange', lazyload);
    }
  });
};
