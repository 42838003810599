import AOS from 'aos';
import lightbox2 from 'lightbox2';
import particlesJS from 'particles.js';
import smoothscroll from 'smoothscroll-polyfill';
import lazyload from './lazyload';
// import Form from './form';
import MobileMenu from './mobile-menu';
import SetupNav from './setup-nav';
import SetupThemes from './setup-themes';
import SetupSliders from './setup-sliders';

if (module.hot) {
  module.hot.accept();
}

AOS.init();
window.particlesJS.load('particles-js', '/static/particles.json', SetupThemes);
window.lightbox = lightbox2;
smoothscroll.polyfill();
lazyload();
SetupNav();
SetupSliders();

document.querySelectorAll('.nav .link a').forEach((link) => {
  link.addEventListener('click', (ev) => {
    ev.preventDefault();
    window.scrollTo({
      top: window.scrollY + document.querySelector(link.attributes.href.value).getBoundingClientRect().top - document.querySelector('nav.nav').offsetHeight,
      behavior: 'smooth',
    });
  });
});

document.querySelector('.triangles .triangle:nth-child(3)').addEventListener('click', (ev) => {
  ev.preventDefault();
  window.scrollTo({
    top: window.scrollY + document.querySelector('#contact').getBoundingClientRect().top - document.querySelector('nav.nav').offsetHeight,
    behavior: 'smooth',
  });
});

const addTouchClass = () => {
  document.body.classList.add('touch');
  window.removeEventListener('touchstart', addTouchClass);
};

window.addEventListener('touchstart', addTouchClass);


// Cookie alert
if (!localStorage.getItem('cookie-accepted'))
  document.querySelector('#cookies-eu-banner').style.display = "block";

document.querySelector('#cookies-eu-accept').addEventListener('click', () => {
  localStorage.setItem('cookie-accepted', true);
  document.querySelector('#cookies-eu-banner').style.display = "none";
});